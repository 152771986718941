<template>
  <div v-if="user" class="d-flex" @click="showDetail(user.id)">
    <b-avatar
      v-if="user.avatar_url"
      variant="primary"
      :src="user.avatar_url"
      class="mr-1"
    ></b-avatar>
    <b-avatar
      v-else
      variant="primary"
      :text="user.initial_letters"
      class="mr-1"
    ></b-avatar>
    <div>
      <strong>{{ fullName }}</strong>
      <div>{{ user.email }}</div>
      <div v-if="showPhone">{{ user.phone }}</div>
    </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
import { props } from 'vue2-dropzone'
export default {
  name: 'UserBadge',
  components: {
    BAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    showPhone: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullName() {
      return this.user.full_name || `${this.user.first_name} ${this.user.first_surname || ''}`
    },
  },
  methods: {
    showDetail(userId) {
      if(this.disabled) return
      this.$router.push({ name: 'client-detail', params: { id: userId } })
    }
  },
}
</script>
