<template>
  <div class="item">
    <div class="icon" :style="{ background: color }">
      <feather-icon icon="UsersIcon" size="2x" />
    </div>

    <div class="right">
      <span class="count">{{ count }}</span>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  props: {
    count: {
      type: Number,
      default: '0',
    },
    title: {
      type: String,
      default: 'Total de Usuarios',
    },
    color: {
      type: String,
      default: '#3d52ff',
    },
  },
  components: {
    BCard,
  },
}
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  background: white;
  padding: 1rem 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.5rem;
  height: 100%;
}

.count {
  font-size: 2rem;
  font-weight: 600;
}

.right {
  margin-left: 2rem;
}

p {
  font-weight: bold;
}

.icon {
  background: red;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
