<template>
  <b-form-checkbox
    switch
    size="lg"
    v-model="active"
    @change="$event => changeActiveStatus($event)"
    variant="success"
  />
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { inputAlert } from '@/helpers/utils'

export default {
  name: 'UserChangeActiveStatus',
  components: {
    BFormCheckbox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      active: false,
    }
  },
  methods: {
    async changeActiveStatus(e) {
      this.loading = true
      try {
        let confirm = {
          isConfirmed: false,
          value: '',
        }
        if (!e) {
          confirm = await inputAlert(
            'Motivo',
            'Ingrese el montivo del bloqueo del usuario',
          )
          if (!confirm.isConfirmed) {
            this.active = true
            return
          }
        }
        const res = await this.$store.dispatch('clients/changeActiveStatus', {
          id: this.user.id,
          active: e,
          motive: !e ? 'block_user' : 'unblock_user',
          description: confirm.value
        })

        if (res?.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        this.$store.dispatch('alert/error', error)
      }
      this.loading = false
    },
  },
  mounted() {
    this.active = this.user.active
  },
}
</script>
