<template>
    <div>
        <b-card no-body class="mb-1" v-for="pba in platformBankAccounts" :key="pba.id">
        <div v-if="haveManageAccount(pba)">
        <div class="d-flex align-items-center border p-1" v-b-toggle="'accordion-'+pba.id">
            <b-avatar
            v-if="pba.icon_url"
            variant="primary"
            :src="pba.icon_url"
            class="mr-1"
            size="sm"
            ></b-avatar>
            <div>
            <strong>{{ pba.name }}</strong>
            </div>
        </div>
        <b-collapse visible :id="'accordion-'+pba.id" role="tabpanel">
            <b-card-body class="pl-3 border-left border-bottom border-right">
            <b-card no-body class="mb-1" v-for="b in pba.banks" :key="b.id">
                <div v-if="havePlatformBankAccount(b.platform_bank.accounts)">
                <div class="d-flex align-items-center pt-1" v-b-toggle="'accordion-'+b.id">
                <b-avatar
                v-if="b.icon_url"
                variant="primary"
                :src="b.icon_url"
                class="mr-1"
                size="sm"
                ></b-avatar>
                <div>
                    <strong>{{ b.name }}</strong>
                </div>
                </div>
                <b-collapse visible :id="'accordion-'+b.id" role="tabpanel">
                <b-card-body>
                    <div v-for="a in b.platform_bank.accounts" :key="a.id">
                        <b-form-checkbox
                            v-if="userData.manage_accounts.includes(a.id)"
                            class="mb-1 ml-2"
                            :checked="true"
                            :disabled="true"
                            >
                            {{ a.values.type }} -
                            {{ a.values.number }}
                        </b-form-checkbox>
                    </div>
                </b-card-body>
                </b-collapse>
                </div>
            </b-card>

            </b-card-body>
        </b-collapse>
        </div>
        </b-card>
    </div>
</template>

<script>
import { BAvatar, BCollapse, BFormCheckbox, BFormCheckboxGroup, BCard, BCardBody, VBToggle } from 'bootstrap-vue'
import { mapState } from 'vuex'
export default {
    name: 'ShowUserManageAccounts',
    components: {
        BAvatar,
        BCollapse,
        BFormCheckbox,
        BFormCheckboxGroup,
        BCard,
        BCardBody
    },
    directives: {
        'b-toggle': VBToggle,
    },
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            platformBankAccounts: (state) =>
                state.app.authInitData.platform_bank_accounts,
        }),
    },
    methods: {
        async getPlatformBaknAccount() {
            await this.$store.dispatch('app/getPlatformBankAccounts', {})
        },
        haveManageAccount(pba) {
            let have = false
            pba.banks.forEach(b => {
                b.platform_bank.accounts.forEach(a => {
                    if (this.userData.manage_accounts.includes(a.id)) {
                        have = true
                    }
                })
            })
            return have
        },
        havePlatformBankAccount(accounts) {
            let have = false
            accounts.forEach(a => {
                    if (this.userData.manage_accounts.includes(a.id)) {
                        have = true
                    }
                })
            return have
        }
    },
    mounted() {
        this.getPlatformBaknAccount()
    }
}

</script>