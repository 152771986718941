<template>
  <span v-if="user" :class="['badge', statusClass]">
    {{ statusText }}
  </span>
</template>
<script>
export default {
  name: 'UserStatusBadge',
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusClass() {
      let cssClass = this.user?.verified ? 'completed' : 'no-verified'
      if(this.user.document_verifications_count > 0 && cssClass !== 'completed') cssClass = 'pending'
      return cssClass
    },
    statusText() {
      let text = this.user?.verified ? 'Verificado' : 'No verificado'
      if(this.user.document_verifications_count > 0 && text !== 'Verificado') text = 'Pendiente'
      return text
    },
  },
}
</script>
<style scoped>
.completed {
  background-color: #6bf182;
  color: #05971d;
}

.rejected {
  background-color: #ff7a7a;
  color: #a70000;
}

.pending {
  background-color: #ffd14f;
  color: #bc9d12;
}

.no-verified {
  background-color: #d3d3d3;
  color: #707070;
}

.badge {
  padding: 0.8rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 25px;
  text-transform: capitalize;
}
</style>
