<template>
  <div>
    <b-row class="mb-2">
      <b-col md="9">
        <admin-users-table-filters @filtersChanged="filtersChanged" />
      </b-col>
      <b-col md="3">
        <card-count-users :count="totalRows" />
      </b-col>
    </b-row>

    <b-card>
      <b-row class="pb-1">
        <b-col md="3" cols="6">
          <b-form-input v-model="searchValue" placeholder="Buscar" debounce="400" />
        </b-col>
        <b-col md="9" cols="6" class="text-right">
          <b-button variant="primary" class="px-1" @click="create()"
            v-if="permissions_requerid.includes('users-admin-create')">
            <feather-icon size="1.2x" icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-table hover responsive="sm" :items="items" :fields="fields" :per-page="perPage" :busy="loading" show-empty>
        <template #cell(full_name)="data">
          <user-badge :user="data.item" />
        </template>

        <!-- country_of_residence -->
        <template #cell(residence_country)="data">
          <country-flag-icon :country="data.item.residence_country" />
        </template>

        <template #cell(role)="data">
          {{ data.item.role ? data.item.role.name : '' }}
        </template>

        <template #cell(asignacion)="data">
          <feather-icon icon="ExternalLinkIcon" size="2x" stroke-width="1" @click="openManageAccounts(data.item)"/>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <user-change-active-status :user="data.item"
              v-if="permissions_requerid.includes('activate-and-deactivate-user')" />

            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>
              <b-dropdown-item @click="edit(data.item)"
                v-if="permissions_requerid.includes('users-admin-edit')">Editar</b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.item)"
                v-if="permissions_requerid.includes('users-admin-delete')">Eliminar</b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-5 pt-3">
            <b-spinner type="grow" class="align-middle"></b-spinner>
            <strong class="ml-1">Cargando</strong>
          </div>
        </template>

        <template #empty>
          <div class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center">
            <feather-icon icon="AlertCircleIcon" size="1.5x" />
            <strong class="ml-1">No hay registros para mostrar</strong>
          </div>
        </template>
      </b-table>
      <b-row v-show="items.length > 0 && !loading">
        <b-col md="2" sm="12" class="d-none d-sm-block">
          <b-form-group class="mb-0">
            <label class="d-inline-block mr-50">Por página</label>
            <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions" @change="perPageChange"
              style="width: auto" />
          </b-form-group>
        </b-col>
        <b-col v-show="totalRows > perPage" md="10" sm="12">
          <b-pagination striped hover responsive first-number last-number :value="currentPage" :total-rows="totalRows"
            :per-page="perPage" align="right" size="sm" class="my-0" @change="changePage" />
        </b-col>
      </b-row>

      <b-sidebar width="25%" v-model="open" bg-variant="white" :title="titleSideBar" right backdrop shadow>
        <AddUpdateClient
          :item="item"
          @saved="() => openSidebar(false)"
          :roleOptions="roleOptions"
          :countryOptions="countryOptions"
          :permissions="permissions_requerid"
        />
      </b-sidebar>
    </b-card>

      <b-modal
          size="lg"
          id="show-image-modal"
          v-model="showManageAccountsModal"
          title="Cuentas que maneja el usuario"
          ok-only
          ok-title="Cerrar"
        >

        <show-user-manage-accounts :userData="userData" />
      </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
  BModal
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import AddUpdateClient from './components/AddUpdateAdmin'
import { confirmAlert } from '@/helpers/utils'

import CountryFlagIcon from '@/components/CountryFlagIcon'
import UserStatusBadge from '@/components/UserStatusBadge'
import UserBadge from '@/components/UserBadge'
import AdminUsersTableFilters from '@/components/AdminUsersTableFilters'
import UserChangeActiveStatus from '@/components/UserChangeActiveStatus'
import CardCountUsers from '@/components/CardCountUsers'
import { verifyPermission } from '@/router';
import ShowUserManageAccounts from '@/components/ShowUserManageAccounts'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    AddUpdateClient,
    CountryFlagIcon,
    UserStatusBadge,
    UserBadge,
    AdminUsersTableFilters,
    UserChangeActiveStatus,
    CardCountUsers,
    BModal,
    ShowUserManageAccounts
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      open: false,
      showManageAccountsModal: false,
      userData: null,
      searchValue: '',
      item: null,
      titleSideBar: "",
      fields: [
        {
          key: 'full_name',
          label: 'Nombre',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Teléfono',
          sortable: true,
        },
        {
          key: 'residence_country',
          label: 'País',
          sortable: true,
        },
        {
          key: 'role',
          label: 'Rol',
          sortable: true,
        },
        {
          key: 'asignacion',
          label: 'Asignación',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
      roleOptions: [],
      countryOptions: [],
      permissions_requerid: []
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.admins.items,
      perPage: (state) => state.admins.perPage,
      currentPage: (state) => state.admins.currentPage,
      totalRows: (state) => state.admins.totalRows,
      pageOptions: (state) => state.admins.pageOptions,
      searchTerm: (state) => state.admins.searchTerm,
      loading: (state) => state.admins.loading,
    }),
  },
  methods: {
    openManageAccounts(item) {
      this.userData = item
      this.showManageAccountsModal = true
    },
    filtersChanged(filters) {
      this.getItems(1, filters)
    },
    create() {
      this.item = null
      this.titleSideBar = 'Nuevo usuario'
      this.openSidebar()
    },
    edit(item) {
      this.item = item
      this.titleSideBar = 'Editar usuario'
      this.openSidebar()
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este registro?')
      if (res.value) {
        const r = await this.$store.dispatch('admins/delete', {
          id: item.id,
        })
        this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1, filters = {}) {
      this.$store.dispatch('admins/get', {
        currentPage: page,
        filters,
      })
    },
    async getRolesAdmin() {
      const res = await this.$store.dispatch('admins/getRolesAdmin', {})
      if (res.data != undefined && res.data.length > 0) {
        this.roleOptions = res.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
            id: item.id,
          }
        })
      }
    },
    async getCountryOptions() {
      const res = await this.$store.dispatch('admins/getCountryOptions', {})
      if (res.data != undefined && res.data.length > 0) {

        this.countryOptions = res.data.map((item) => {
          return {
            value: item.iso_code,
            label: item.name,
          }
        })
      }
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('admins/perPageChange', perPage)
      this.getItems()
    },
    search(value) {
    },
  },
  mounted() {
    this.$store.dispatch('admins/searchTermChange', '')
    this.getItems()
    this.getRolesAdmin()
    this.getCountryOptions()


    // Verify
    let create = verifyPermission({ namePerm: 'users-admin-create' })
    let edit = verifyPermission({ namePerm: 'users-admin-edit' });
    let delete_var = verifyPermission({ namePerm: 'users-admin-delete' })
    let change_status_user = verifyPermission({ namePerm: 'activate-and-deactivate-user' })
    let change_password = verifyPermission({ namePerm: 'change-password' })

    let permissions_requerid_verify = []
    if (create) {
      permissions_requerid_verify.push("users-admin-create")
    }
    if (edit) {
      permissions_requerid_verify.push("users-admin-edit")
    }
    if (delete_var) {
      permissions_requerid_verify.push("users-admin-delete")
    }
    if (change_status_user) {
      permissions_requerid_verify.push("activate-and-deactivate-user")
    }
    if (change_password) {
      permissions_requerid_verify.push("change-password")
    }

    this.permissions_requerid = permissions_requerid_verify

  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('admins/searchTermChange', value)
      this.getItems()
    },
  },
}
</script>

<style>
.badge-primary {
  background-color: #94a0ff;
}
</style>
