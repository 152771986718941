<template>
  <b-card style="height: 100%">
    <b-row>
      <b-col md="12">
        <h4>Filtrar usuarios</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <v-select
          v-model="filters.country"
          :options="countries"
          :reduce="(val) => val.iso_code"
          label="name"
          placeholder="Pais"
        />
      </b-col>
      <b-col>
        <v-select
          v-model="filters.role"
          :options="roles"
          :reduce="(val) => val.id"
          label="name"
          placeholder="Rol"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BFormGroup, BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState } from 'vuex'

export default {
  name: 'UsersTableFilters',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BCard,
  },
  data() {
    return {
      filters: {
        country: null,
        role: null,
      },
    }
  },
  computed: {
    ...mapState({
      countries: (state) => state.app.initData.countries,
      roles: (state) => state.app.initData.roles,
    }),
  },
  watch: {
    filters: {
      handler() {
        this.$emit('filtersChanged', this.filters)
      },
      deep: true,
    },
  },
}
</script>
