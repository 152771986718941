<template>
  <div>
    <validation-observer ref="createAdminForm">
      <b-form class="mt-2" @submit.prevent="createAdmin">
        <b-container>
          <b-row>
            <b-col>
              <b-form-group label="Nombre" label-for="userData-name">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input
                    id="userData-name"
                    v-model="userData.first_name"
                    :state="errors.length > 0 ? false : null"
                    name="first_name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email" label-for="userData-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="userData-email"
                    v-model="userData.email"
                    :state="errors.length > 0 ? false : null"
                    name="userData-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Teléfono" label-for="userData-phone">
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="required"
                >
                  <b-form-input
                    id="userData-phone"
                    v-model="userData.phone"
                    :state="errors.length > 0 ? false : null"
                    name="userData-phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="Rol del usuario" rules="required">
                <b-form-group label="Rol del usuario" label-for="user-role" :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.role" :options="roleOptions" :reduce="val => val.value" :clearable="false"
                    input-id="user-role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider #default="{ errors }" name="País del usuario" rules="required">
                <b-form-group label="País del usuario" label-for="user-country"
                  :state="errors.length > 0 ? false : null">
                  <v-select v-model="userData.country" :options="countryOptions" :reduce="val => val.value"
                    :clearable="false" input-id="user-country" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <b-form-group v-if="!item || (item && permissions && permissions.includes('change-password'))" label="Contraseña" label-for="userData-password">
                <validation-provider #default="{ errors }" name="Contraseña" :rules="!userData.id ? 'required' : ''">
                  <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="userData-password" v-model="userData.password"
                      :state="errors.length > 0 ? false : null" name="password"
                      :type="showPassword ? 'text' : 'password'" />

                    <div class="input-group-append" click="showPassword = !showPassword">
                      <div class="input-group-text">
                        <b-button variant="default" class="p-0" @click="updateIconPass()">
                          <feather-icon :icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'" class="m-0 red" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col md="12 my-2" v-if="userData.role && userData.role !== 'superadmin'">
              <b-form-group label="Este usuario puede atenteder">
                <b-form-checkbox-group
                  v-model="userData.manage_transactions"
                >
                  <b-form-checkbox value="recharges">Recargas</b-form-checkbox>
                  <b-form-checkbox value="withdrawals">Retiros</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="userData.role && userData.role !== 'superadmin'">
              <h5 class="py-1">Elige que cuentas por metodo de pago podra atender este usuario</h5>

              <b-card no-body class="mb-1" v-for="pba in platformBankAccounts" :key="pba.id">
                <div class="d-flex align-items-center border p-1" v-b-toggle="'accordion-'+pba.id">
                  <b-avatar
                    v-if="pba.icon_url"
                    variant="primary"
                    :src="pba.icon_url"
                    class="mr-1"
                    size="sm"
                  ></b-avatar>
                  <div>
                    <strong>{{ pba.name }}</strong>
                  </div>
                </div>
                <b-collapse :id="'accordion-'+pba.id" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="pl-3 border-left border-bottom border-right">
                    <b-card no-body class="mb-1" v-for="b in pba.banks" :key="b.id">
                      <div class="d-flex align-items-center pt-1 justify-content-between">
                        <div class="w-100" v-b-toggle="'accordion-'+b.id">
                        <b-avatar
                          v-if="b.icon_url"
                          variant="primary"
                          :src="b.icon_url"
                          class="mr-1"
                          size="sm"
                        ></b-avatar>
                          <strong>{{ b.name }}</strong>
                        </div>
                        <span v-if="b.platform_bank_accounts.length > 0" style="width:60%" @click="checkAll(b.platform_bank_accounts)" class="text-primary cursor-pointer">
                          {{ !isSubset(b.platform_bank_accounts, userData.manage_accounts) ? 'Marcar' : 'Desmarcar' }} todo
                        </span>
                      </div>
                      <b-collapse :id="'accordion-'+b.id" accordion="other-accordion" role="tabpanel">
                        <b-card-body>
                          <b-form-checkbox-group v-model="userData.manage_accounts">
                          <b-form-checkbox
                            v-for="a in b.platform_bank_accounts"
                            :key="a.id"
                            class="mb-1 ml-2"
                            :value="a.id"
                          >
                            {{ a.values.type }} -
                            {{ a.values.number }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- <b-form-checkbox-group v-model="userData.manage_accounts">
                <b-form-checkbox
                  v-for="pba in platformBankAccounts"
                  :key="pba.id"
                  class="mb-1 ml-2"
                  :value="pba.id"
                >
                  {{ pba.platform_bank.bank.name }} - {{ pba.values.type }} -
                  {{ pba.values.number }}
                </b-form-checkbox>
              </b-form-checkbox-group> -->
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div
                class="d-flex justify-content-center align-items-center py-5"
              >
                <b-button type="submit" variant="primary">Guardar</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BCard,
  VBToggle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BCardHeader,
  BCardText,
  BCollapse,
  BCardBody,
  BAvatar
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
// import formValidation from '@core/comp-functions/forms/form-validation'

const userDataInitialData = {
  id: null,
  first_name: null,
  email: null,
  phone: null,
  password: null,
  surname: null,
  role: null,
  manage_accounts: [],
  manage_transaction: [],
  country: null,
}

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
    BCard,
    FeatherIcon,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCardHeader,
    BCardText,
    BCollapse,
    BCardBody,
    BAvatar
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: Object.assign({}, userDataInitialData),
      showPassword: false
    }
  },
  computed: {
    ...mapState({
      platformBankAccounts: (state) =>
        state.app.authInitData.platform_bank_accounts,
      platformTransactions: (state) =>
        state.app.initData.platform_transaction_types,
    }),
  },
  methods: {
    // compare two arrays and return the number of elements that are the same
    compareArrays(arr1, arr2) {
      const acs = arr1.map(a => a.id)
      return acs.filter((val) => arr2.includes(val)).length
    },
    // check if all the elements of an array are in another array
    isSubset(arr1, arr2) {
      const acs = arr1.map(a => a.id)
      return acs.every((val) => arr2.includes(val))
    },
    checkAll(accounts) {
      const acs = accounts.map(a => a.id)
      if (this.isSubset(accounts, this.userData.manage_accounts)) {
        this.userData.manage_accounts = this.userData.manage_accounts.filter(
          (a) => !acs.includes(a)
        )
      } else {
        this.userData.manage_accounts = [...new Set(this.userData.manage_accounts.concat(acs))]
      }
    },
    async getPlatformBaknAccount() {
      await this.$store.dispatch('app/getPlatformBankAccounts', {})
    },
    updateIconPass() {
      this.showPassword = !this.showPassword
    },
    createAdmin() {
      this.$refs.createAdminForm.validate().then(async (success) => {
        if (success) {
          const res = await this.$store.dispatch('admins/save',
            {
              id: this.userData.id,
              name: this.userData.first_name,
              email: this.userData.email,
              phone: this.userData.phone,
              password: this.userData.password,
              role: this.userData.role,
              country: this.userData.country,
              manage_accounts: this.userData.manage_accounts,
              manage_transactions: this.userData.manage_transactions,
            }
          )
          this.userData = Object.assign({}, userDataInitialData)
          this.$refs.createAdminForm.reset()
          this.$emit('saved', res)

          if (res.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Usuario guardado correctamente',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$store.dispatch('admins/get', {
              currentPage: 1,
            })
          } else {
            this.$swal('Error', 'Hubo un error al guardar el usuario', 'error')
          }
        }
      })
    },
  },
  watch: {
    item: {
      handler: function (val) {
        if (val) {
          this.userData = Object.assign({}, val)
          if (val.role_id != undefined) {
            let rolSelect = this.roleOptions.find(
              (itemFind) => itemFind.id == val.role_id
            )
            if (rolSelect != undefined) {
              this.userData.role = rolSelect.value
            }

            this.userData.manage_accounts = val.manage_accounts ?? []
            this.userData.manage_transactions = val.manage_transactions ?? []
          }

          if (val.country_code != undefined) {
            let countrySelect = this.countryOptions.find((itemFind) => itemFind.value == val.country_code)
            if (countrySelect != undefined) {
              this.userData.country = countrySelect.value
            }
          }

        } else {
          this.userData = Object.assign({}, userDataInitialData)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPlatformBaknAccount()
  },
}
</script>
