<template>
  <div v-if="country" class="d-flex align-items-center">
    <b-avatar
      v-if="country.flag_icon"
      variant="primary"
      :src="country.flag_icon"
      class="mr-1"
      size="sm"
    ></b-avatar>
    <div>
      <strong>{{ country.iso_code }}</strong>
    </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
export default {
  name: 'CountryFlagIcon',
  components: {
    BAvatar,
  },
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
}
</script>
